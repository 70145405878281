import {
  Component as WebComponent,
  ElementCreator,
  elements,
  vars,
} from 'xinjs'

import { Page, emptyPage } from '../functions/shared/page'

export class XinPage extends WebComponent {
  page: Page = { ...emptyPage }

  content = null

  constructor() {
    super()
  }

  render() {
    super.render()

    this.innerHTML = this.page.source
  }
}

export const xinPage = XinPage.elementCreator({
  tag: 'xin-page',
}) as ElementCreator<XinPage>

const PROJECT_ID = 'liquid-force-425209-g2'

export const PRODUCTION_BASE = `//us-central1-${PROJECT_ID}.cloudfunctions.net/`

export const config = {
  authDomain: `${PROJECT_ID}.firebaseapp.com`,
  projectId: PROJECT_ID,
  storageBucket: `${PROJECT_ID}.appspot.com`,
  apiKey: 'AIzaSyCeQLQmTbCIyhBTVttZMviVQHgRbiqAuHo',
  messagingSenderId: '421540221791',
  appId: '1:421540221791:web:e3110b907a24d0a60e1cea',
  measurementId: 'G-L5L18J8LCM',
}

import { service } from './firebase'

declare global {
  interface Window {
    prefetched?: { [key: string]: any }
  }
}

export async function getPrefetchedDoc(
  path: string,
  fetchIfNeeded = true
): Promise<any> {
  if (window.prefetched && Object.keys(window.prefetched).includes(path)) {
    console.log(`${path} %cprefetched`, 'background: green; color: white')
    return window.prefetched[path]
  } else if (fetchIfNeeded) {
    console.log(
      `${path} %cnot prefetched, fetching...`,
      'background: purple; color: white'
    )
    return service.doc.get({ p: path })
  } else {
    console.log(`${path} %cnot prefetched`, 'background: purple; color: white')
    return undefined
  }
}

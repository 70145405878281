export interface Prefetch {
  regexp: string,
  path: string,
}

export interface Page {
  title: string
  description: string
  path: string
  imageUrl: string
  source: string
  prefetch: Prefetch[]
}

export const emptyPage: Page = {
  title: '',
  description: '',
  path: '',
  imageUrl: '',
  source: '',
  prefetch: [],
}
